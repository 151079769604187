<template>
  <view-item title="短信管理">
    <en-card class="h-full" body-class="h-full">
      <flex-box>
        <template #default="{ height }">
          <en-scrollbar :height="height">
            <en-form :model="form.data" :rules="form.rules" :ref="setRef('form')" label-position="right" label-width="80">
              <en-form-item label="剩余短信">{{ form.balance.data }}条</en-form-item>
              <en-form-item label="短信套餐">
                <div class="flex items-center gap-6">
                  <div
                    v-for="item of form.combo.data"
                    :class="[
                      'cursor-pointer w-25 border border-solid border-gray-200 flex flex-col divide-y',
                      {
                        'bg-primary text-white': form.combo.active?.id === item.id
                      }
                    ]"
                    @click="form.combo.item.click(item)"
                  >
                    <span class="w-full flex justify-center">{{ item.count }}条</span>
                    <span class="w-full flex justify-center">{{ formatMoney(item.price) }}</span>
                  </div>
                </div>
              </en-form-item>
              <en-form-item label="开票信息" prop="invoiceType">
                <div class="flex items-center gap-6">
                  <span>{{ form.data.invoiceType?.message }}</span>
                  <en-button type="primary" text @click="form.invoice.click">{{ form.data.invoiceType?.code ? '编辑' : '修改' }}</en-button>
                </div>
              </en-form-item>
              <en-form-item>
                <button-ajax :method="form.charge.click">立即充值</button-ajax>
              </en-form-item>
            </en-form>

            <en-collapse :model-value="['1']">
              <en-collapse-item title="充值记录" name="1">
                <en-table
                  :data="table.data"
                  :height="400"
                  :method="store.user.tenant?.id === 'ENOCH' ? table.getByEnoch : table.getByTenant"
                  :paging="table.paging"
                  pagination
                  :loading="table.loading"
                >
                  <en-table-column label="订单时间" prop="preparedDatetime">
                    <template #default="{ row }: { row: ShortMessageTopUpComboOrderDto }">{{ formatDate(row.preparedDatetime) }}</template>
                  </en-table-column>
                  <en-table-column label="订单编号" prop="serialNo"></en-table-column>
                  <en-table-column label="充值金额" prop="amount">
                    <template #default="{ row }: { row: ShortMessageTopUpComboOrderDto }">{{ formatMoney(row.amount) }}</template>
                  </en-table-column>
                  <en-table-column label="充值数量" prop="count"></en-table-column>
                  <en-table-column label="充值状态" prop="status.message"></en-table-column>
                  <en-table-column label="充值人" prop="preparedBy"></en-table-column>
                </en-table>
              </en-collapse-item>
            </en-collapse>
          </en-scrollbar>
        </template>
      </flex-box>
    </en-card>
  </view-item>

  <en-drawer v-model="invoice.visible" title="开票信息">
    <en-form :model="invoice.form.data" :rules="invoice.form.rules" :ref="setRef('invoiceForm')">
      <en-form-item label="发票类型" prop="invoiceType.code">
        <select-maintain
          v-model="invoice.form.data.invoiceType"
          :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['IVCTP']) }"
          :props="{ label: 'message', value: '' }"
          value-key="code"
          @change="invoice.form.invoiceType.change"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="发票抬头" prop="invoiceTitle">
        <en-input v-model="invoice.form.data.invoiceTitle"></en-input>
      </en-form-item>
      <en-form-item label="开票内容" prop="invoiceContents.code">
        <select-maintain
          v-model="invoice.form.data.invoiceContents"
          :ajax="{ action: 'GET /enocloud/common/lookup/:lookupType', params: (params) => (params.paths = ['IVCCTS']) }"
          :props="{ label: 'message', value: '' }"
          value-key="code"
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="联系人电话" prop="invoiceContactPhone">
        <en-input v-model="invoice.form.data.invoiceContactPhone"></en-input>
      </en-form-item>
      <en-form-item label="联系人地址" prop="invoiceContactAddress">
        <en-input v-model="invoice.form.data.invoiceContactAddress"></en-input>
      </en-form-item>
      <en-form-item label="纳说人识别号" prop="taxpayerIdentificationNumber">
        <en-input v-model="invoice.form.data.taxpayerIdentificationNumber"></en-input>
      </en-form-item>
      <en-form-item label="银行基本账户" prop="bankAccount">
        <en-input v-model="invoice.form.data.bankAccount"></en-input>
      </en-form-item>
      <en-form-item label="开户行" prop="bankName">
        <en-input v-model="invoice.form.data.bankName"></en-input>
      </en-form-item>
    </en-form>

    <template #footer>
      <en-button @click="invoice.footer.cancel.click">取消</en-button>
      <en-button type="primary" @click="invoice.footer.confirm.click">确定</en-button>
    </template>
  </en-drawer>
</template>

<script lang="ts">
import { h } from 'vue'
import { formatMoney } from '@enocloud/utils'
import { EnMessage, EnMessageBox } from '@enocloud/components'
import type { FormRules } from '@enocloud/components'

type ShortMessageTopUpComboOrderDto =
  | EnocloudShortMessageDefinitions['ShortMessageTopUpComboOrderDto']
  | EnosprayShortMessageDefinitions['ShortMessageTopUpComboOrderDto']

const allReequest = async function (this: any) {
  this.form.balance.get()
  this.table[this.store.user.tenant?.id === 'ENOCH' ? 'getByEnoch' : 'getByTenant']()
}

export default factory({
  config: {
    children: {
      form: {
        data: {} as EnocloudShortMessageDefinitions['ShortMessageTopUpComboOrderDto'],
        rules: {},
        ajax: {
          submit: {
            action: 'POST /enocloud/short/message/combo/order',
            params(params) {
              params.payload = this.form.data
            }
          }
        },
        children: {
          balance: {
            ajax: {
              get: {
                action: 'GET /enocloud/short/message/combo/balance',
                data: 'number'
              }
            }
          },
          combo: {
            active: null as EnocloudShortMessageDefinitions['ShortMessageTopUpComboDto'] | null,
            ajax: {
              get: {
                action: 'GET /enocloud/short/message/combo',
                data: 'array',
                params(params) {
                  params.payload = { statusCode: 'A' }
                }
              }
            },
            children: {
              item: {
                click(data: EnocloudShortMessageDefinitions['ShortMessageTopUpComboDto']) {
                  this.form.combo.active = data
                }
              }
            }
          },
          charge: {
            async click() {
              const { combo, data } = this.form
              if (!data.invoiceType?.code) return EnMessage.info('请填写开票信息')

              const validteProp = (...props: Array<keyof EnocloudShortMessageDefinitions['ShortMessageTopUpComboOrderDto']>) => {
                let valid = true
                props.forEach((p) => {
                  if (!valid) return
                  valid = Boolean(data[p])
                })
                return valid
              }

              switch (data.invoiceType?.code) {
                case 'C':
                  if (!validteProp('invoiceTitle', 'invoiceContactPhone', 'invoiceContactAddress')) {
                    return EnMessage.info('请填写必填信息')
                  }
                case 'S':
                  if (
                    !validteProp(
                      'invoiceTitle',
                      'invoiceContactPhone',
                      'invoiceContactAddress',
                      'taxpayerIdentificationNumber',
                      'bankAccount',
                      'bankName'
                    )
                  ) {
                    return EnMessage.info('请填写必填信息')
                  }
              }

              const action = await EnMessageBox({
                title: '确认充值',
                showCancelButton: true,
                message: h('div', { class: 'w-100 flex flex-col gap-6' }, [
                  h('div', { class: 'mx-auto w-25 bg-primary border border-solid border-gray-200 flex flex-col divide-y text-white ' }, [
                    h('span', { class: 'w-full flex justify-center' }, combo.active?.count + '条'),
                    h('span', { class: 'w-full flex justify-center' }, formatMoney(combo.active?.price))
                  ]),
                  h(
                    'p',
                    `你已选中以上套餐，确认充值请点击确定按钮，并及时将充值款项 ${formatMoney(
                      combo.active?.price
                    )}打到以下银行账户，充值款项到账后短信会在T+1个工作日内到账。`
                  ),
                  h('div', { class: 'grid grid-cols-2' }, [
                    h('span', '账户名称:杭州以诺行汽车科技股份有限公司'),
                    h('span', '开户行:中国工商银行杭州武林支行'),
                    h('span', '账号:1202021219800011356')
                  ]),
                  h('p', '打款完成后请及时联系我们'),
                  h('p', '联系方式：0571-89774981(工作日09:00-18:00)')
                ])
              })

              if (action === 'confirm') {
                await this.form.submit()
                return allReequest.call(this)
              }
            }
          },
          invoice: {
            click() {
              this.invoice.form.init()
              Object.assign(this.invoice.form.data, this.form.data)
              this.invoice.visible = true
            }
          }
        }
      },
      table: {
        ajax: {
          getByTenant: {
            action: 'GET /enocloud/short/message/combo/order',
            data: 'array',
            loading: true,
            pagination: true
          },
          getByEnoch: {
            action: 'GET /enospray/short/message/combo/order',
            data: 'array',
            loading: true,
            pagination: true
          }
        },
        method() {}
      },
      charge: {
        visible: false
      },
      invoice: {
        visible: false,
        children: {
          form: {
            data: {} as EnocloudShortMessageDefinitions['ShortMessageTopUpComboOrderDto'],
            computed: {
              rules() {
                const rules: FormRules = {
                  'invoiceType.code': [{ required: true, message: '请选择发票类型' }]
                }
                switch (this.invoice.form.data.invoiceType?.code) {
                  case 'C':
                    Object.assign(rules, {
                      invoiceTitle: [{ required: true, message: '请填写发票抬头' }],
                      invoiceContactPhone: [{ required: true, message: '请填写联系人电话' }],
                      invoiceContactAddress: [{ required: true, message: '请填写联系人地址' }],
                      taxpayerIdentificationNumber: [{ required: true, message: '请填写纳说人识别号' }]
                    })
                    break
                  case 'S':
                    Object.assign(rules, {
                      'invoiceContents.code': [{ required: true, message: '请选择开票内容' }],
                      invoiceTitle: [{ required: true, message: '请填写发票抬头' }],
                      invoiceContactPhone: [{ required: true, message: '请填写联系人' }],
                      invoiceContactAddress: [{ required: true, message: '请填写联系人地址' }],
                      taxpayerIdentificationNumber: [{ required: true, message: '请填写纳说人识别号' }],
                      bankAccount: [{ required: true, message: '请填写银行基本账户' }],
                      bankName: [{ required: true, message: '请填写开户行' }]
                    })
                    break
                }
                return rules
              }
            },
            children: {
              invoiceType: {
                change() {
                  this.refs.invoiceForm.clearValidate()
                }
              }
            }
          },
          footer: {
            cancel: {
              click() {
                this.invoice.visible = false
              }
            },
            confirm: {
              async click() {
                await this.refs.invoiceForm.validate()
                Object.assign(this.form.data, this.invoice.form.data)
                this.invoice.visible = false
              }
            }
          }
        }
      }
    }
  },

  async mounted() {
    await this.form.combo.get()
    this.form.combo.active = this.form.combo.data[0]
    allReequest.call(this)
  }
})
</script>
